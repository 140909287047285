<template>
  <div v-loading="loading" class="layout">
    <div
      class="border-gray-200 pb-5 sm:flex sm:items-center sm:justify-between"
    >
      <h3 class="text-lg font-medium leading-6 text-gray-900">System Users</h3>
      <div class="flex">
        <div class="mt-3 sm:mt-0 sm:ml-4 sm:mr-4">
          <label for="search_candidate" class="sr-only">Search</label>
          <div class="flex rounded-md shadow-sm">
            <div class="relative flex-grow focus-within:z-10">
              <div
                class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3"
              >
                <!-- Heroicon name: solid/search -->
                <svg
                  class="h-5 w-5 text-gray-400"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  aria-hidden="true"
                >
                  <path
                    fill-rule="evenodd"
                    d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                    clip-rule="evenodd"
                  />
                </svg>
              </div>
              <input
                type="text"
                v-model="search"
                name="search_candidate"
                id="search_candidate"
                class="search block w-full rounded-none rounded-l-md border-gray-300 pl-10 focus:border-indigo-500 sm:hidden 2xl:focus:ring-indigo-500"
                placeholder="Search"
              />
              <input
                type="text"
                name="search_admin_users"
                id="search_admin_users"
                class="search hidden w-full rounded-none rounded-l-md border-gray-300 pl-10 focus:border-indigo-500 focus:ring-indigo-500 sm:block sm:text-sm"
                placeholder="Search"
                v-loading="inputLoading"
              />
            </div>
            <Menu>
              <div class="relative">
                <MenuButton
                  type="button"
                  class="focus:outline-none relative -ml-px inline-flex items-center rounded-r-md border border-gray-300 bg-gray-50 px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-100 focus:border-indigo-500 focus:ring-1 focus:ring-indigo-500"
                >
                  <!-- Heroicon name: solid/sort-ascending -->
                  <svg
                    class="h-5 w-5 text-gray-400"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      d="M3 3a1 1 0 000 2h11a1 1 0 100-2H3zM3 7a1 1 0 000 2h5a1 1 0 000-2H3zM3 11a1 1 0 100 2h4a1 1 0 100-2H3zM13 16a1 1 0 102 0v-5.586l1.293 1.293a1 1 0 001.414-1.414l-3-3a1 1 0 00-1.414 0l-3 3a1 1 0 101.414 1.414L13 10.414V16z"
                    />
                  </svg>
                  <span class="ml-2">Sort</span>
                  <!-- Heroicon name: solid/chevron-down -->
                  <svg
                    class="ml-2.5 -mr-1.5 h-5 w-5 text-gray-400"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                      clip-rule="evenodd"
                    />
                  </svg>
                </MenuButton>
                <transition
                  enter-active-class="transition duration-100 ease-out"
                  enter-from-class="transform scale-95 opacity-0"
                  enter-to-class="transform scale-100 opacity-100"
                  leave-active-class="transition duration-75 ease-out"
                  leave-from-class="transform scale-100 opacity-100"
                  leave-to-class="transform scale-95 opacity-0"
                >
                  <MenuItems
                    class="focus:outline-none absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white text-left shadow-lg ring-1 ring-black ring-opacity-5"
                    role="menu"
                    aria-orientation="vertical"
                    aria-labelledby="sort-menu"
                  >
                    <div class="py-1" role="none">
                      <MenuItem>
                        <a
                          href="#"
                          class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                          role="menuitem"
                          >Name</a
                        >
                      </MenuItem>
                      <MenuItem>
                        <a
                          href="#"
                          class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                          role="menuitem"
                          >Date modified</a
                        >
                      </MenuItem>
                      <MenuItem>
                        <a
                          href="#"
                          class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                          role="menuitem"
                          >Date created</a
                        >
                      </MenuItem>
                    </div>
                  </MenuItems>
                </transition>
              </div>
            </Menu>
          </div>
        </div>
        <button
          type="button"
          @click="onOpenCreate"
          class="order-0 focus:outline-none inline-flex items-center rounded-md border border-transparent bg-purple-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-purple-700 focus:ring-purple-500 sm:order-1 sm:ml-3"
        >
          Create
        </button>
      </div>
    </div>

    <div class="flex flex-col" v-if="users.length > 0">
      <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
          <div
            class="overflow-hidden border-b border-gray-200 shadow sm:rounded-lg"
          >
            <table class="min-w-full divide-y divide-gray-200">
              <thead class="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                  >
                    Name
                  </th>
                  <th
                    scope="col"
                    class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                  >
                    Roles
                  </th>
                  <th
                    scope="col"
                    class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                  >
                    Organization
                  </th>
                  <th scope="col" class="relative px-6 py-3">
                    <span class="sr-only">Edit</span>
                  </th>
                </tr>
              </thead>
              <tbody class="divide-y divide-gray-200 bg-white">
                <tr v-for="user in users" :key="user.Id">
                  <td class="whitespace-nowrap px-6 py-4 text-left">
                    <div class="flex items-center">
                      <div class="h-10 w-10 flex-shrink-0">
                        <img
                          v-if="user.Picture"
                          class="h-10 w-10 rounded-full"
                          :src="`data:image/png;base64, ${user.Picture}`"
                          alt=""
                        />
                        <svg
                          v-else
                          width="38"
                          height="38"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            color="text-gray-400"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                            d="M5.121 17.804A13.937 13.937 0 0112 16c2.5 0 4.847.655 6.879 1.804M15 10a3 3 0 11-6 0 3 3 0 016 0zm6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                          />
                        </svg>
                      </div>
                      <div class="ml-4">
                        <div class="text-sm font-medium text-gray-900">
                          {{ user.FullName }}
                        </div>
                        <div class="text-sm text-gray-500">
                          {{ user.Username }}
                        </div>
                      </div>
                    </div>
                  </td>
                  <td
                    class="whitespace-nowrap px-6 py-4 text-left text-sm text-gray-500"
                  >
                    <div v-if="user.SystemRoles.length > 0">
                      <span
                        v-if="user.SystemRoles[0] !== undefined"
                        class="inline-flex items-center rounded-full bg-gray-100 px-2.5 py-0.5 text-xs font-medium text-gray-800"
                      >
                        {{ user.SystemRoles[0].Name }}
                      </span>
                      <span
                        v-if="user.SystemRoles.length > 1"
                        class="inline-flex items-center rounded-full bg-gray-100 px-2.5 py-0.5 text-xs font-medium text-gray-800"
                      >
                        + {{ user.SystemRoles.length - 1 }}
                      </span>
                    </div>
                  </td>
                  <td class="whitespace-nowrap px-6 py-4 text-left">
                    <div
                      class="text-sm text-gray-900"
                      v-if="user.OrganizationUsers.length > 0"
                    >
                      {{ user.OrganizationUsers[0].Organization.Name }}
                    </div>
                    <div
                      class="text-sm text-gray-500"
                      v-if="user.OrganizationUsers.length > 0"
                    >
                      {{ user.OrganizationUsers[0].Organization.City }} |
                      {{ user.OrganizationUsers[0].Organization.ZipCode }} |
                      {{ user.OrganizationUsers[0].Organization.State }}
                    </div>
                  </td>
                  <td
                    class="flex space-x-3 whitespace-nowrap px-6 py-4 text-right text-sm font-medium"
                  >
                    <button
                      type="button"
                      v-tippy="{
                        content: 'Edit',
                        animation: 'scale',
                        animateFill: false,
                        theme: 'tomato',
                      }"
                      @click="handleEdit(user.Id)"
                      class="focus:outline-none inline-flex items-center rounded-md border border-transparent px-3 py-2 text-sm font-medium leading-4 text-purple-400 shadow-sm hover:text-purple-800 focus:ring-purple-500"
                    >
                      <PencilIcon class="h-5 w-5 flex-shrink-0 text-gray-400" />
                    </button>
                    <button
                      type="button"
                      v-tippy="{
                        content: 'Delete',
                        animation: 'scale',
                        animateFill: false,
                        theme: 'tomato',
                      }"
                      @click="handleDelete(user.Id)"
                      class="focus:outline-none inline-flex items-center rounded-md border border-transparent px-3 py-2 text-sm font-medium leading-4 text-red-400 shadow-sm hover:text-red-800 focus:ring-red-500"
                    >
                      <TrashIcon class="h-5 w-5 flex-shrink-0 text-red-400" />
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>

            <pagination :pagination="pagination" @changePage="onChangePage" />
          </div>
        </div>
      </div>
    </div>
    <create-user v-if="openCreatePanel" :openPanel="openCreatePanel" />
    <edit-user :user="user" :openPanel="openEditPanel" />
    <el-dialog
      title="Delete user"
      custom-class="dialog-custom"
      v-model="dialog.show"
      destroy-on-close
      width="30%"
    >
      <span
        >Once you delete the user, you will lose all data associated with
        it.</span
      >
      <template #footer>
        <span class="dialog-footer">
          <div class="mt-5">
            <button
              type="button"
              @click="onDelete"
              class="focus:outline-none inline-flex items-center justify-center rounded-md border border-transparent bg-red-100 px-4 py-2 font-medium text-red-700 hover:bg-red-200 focus:ring-red-500 sm:text-sm"
            >
              Delete
            </button>
          </div>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import { Menu, MenuButton, MenuItems, MenuItem } from "@headlessui/vue";
import { TrashIcon, PencilIcon } from "@heroicons/vue/outline";

import Pagination from "@/common/components/pagination";

import CreateUser from "./_createUser";
import EditUser from "./_editUser";

import { directive } from "vue-tippy";

import _ from "lodash";
import API from "@/api";

import "tippy.js/animations/scale.css";

export default defineComponent({
  components: {
    Menu,
    MenuButton,
    MenuItems,
    MenuItem,
    Pagination,
    TrashIcon,
    PencilIcon,
    CreateUser,
    EditUser,
  },
  directives: {
    tippy: directive,
  },
  data() {
    return {
      loading: false,
      inputLoading: false,
      search: "",
      users: [],
      roles: [],
      openCreatePanel: false,
      openEditPanel: false,
      pagination: {
        page: 1,
        pageSize: 10,
        totalResults: 0,
      },
      // createForm: {
      //   firstName: '',
      //   lastName: '',
      //   middleName: '',
      //   username: '',
      //   password: '',
      // },
      selectedUser: {},
      dialog: {
        show: false,
        userId: null,
      },
    };
  },
  created() {
    this.fetchUsers();
    this.fetchRoles();

    this.debouncedGetAnswer = _.debounce(this.getAnswer, 1000);
  },
  // setup() {
  //   let open = ref(false);

  //   const openCreateFormEvent = () => {
  //     open.value = true;
  //     console.log({ open });
  //   };

  //   return {
  //     open,
  //     openCreateFormEvent,
  //   };
  // },
  methods: {
    onOpenCreate() {
      console.log("usao u onOpenCreate", this.openCreatePanel);
      this.openCreatePanel = true;
    },
    async getAnswer() {
      this.inputLoading = true;
      let results = await API.Admin.Users.search(
        this.pagination.page,
        this.pagination.pageSize
      );
      this.inputLoading = false;
      this.users = results.data.List;
      this.pagination.page = results.data.Page;
      this.pagination.pageSize = results.data.PageSize;
      this.pagination.totalResults = results.data.TotalResults;
    },
    async fetchUsers() {
      try {
        this.loading = true;
        let results = await API.Admin.Users.get(
          this.pagination.page,
          this.pagination.pageSize
        );
        this.users = results.data.List;

        this.pagination.page = results.data.Page;
        this.pagination.pageSize = results.data.PageSize;
        this.pagination.totalResults = results.data.TotalResults;

        this.loading = false;
      } catch (error) {
        this.$notify.error({
          title: "Error",
          message: `Problem while fetching users. ${error}`,
        });
        this.loading = false;
      }
    },
    async fetchRoles() {
      try {
        let results = await API.Admin.Roles.all();
        this.roles = results.data.List;
      } catch (error) {
        this.$notify.error({
          title: "Error",
          message: `Problem while fetching roles. ${error}`,
        });
      }
    },
    onSubmit(createForm) {
      console.log({ createForm });
      this.$refs[createForm].validate((valid) => {
        if (valid) {
          try {
            API.Admin.Users.save(this.createForm).then(() => {
              this.drawer.create = false;

              this.$notify({
                title: "Success",
                message: "New user saved",
                type: "success",
              });

              this.fetchUsers();
            });
          } catch (error) {
            this.$notify.error({
              title: "Error",
              message: `Error while saving new user. ${error}`,
            });
          }
        } else {
          //this.resetForm();
          return false;
        }
      });
    },

    resetForm(formName) {
      this.$refs[formName].resetFields();
    },

    onUpdate() {
      try {
        this.editForm.id = this.selectedUser.Id;
        let sysRoles = [];
        this.editForm.systemRoles.forEach((id) => {
          sysRoles.push({
            id: id,
          });
        });

        this.editForm.systemRoles = sysRoles;

        API.Admin.Users.update(this.editForm).then(() => {
          this.drawer.edit = false;

          this.$notify({
            title: "Success",
            message: "User updated",
            type: "success",
          });

          this.fetchUsers();
        });
      } catch (error) {
        this.$notify.error({
          title: "Error",
          message: `Error while updating user. ${error}`,
        });
      }
    },

    onChangePage(page) {
      console.log("usao u page", page);
      this.pagination.page = page;

      this.fetchUsers();
    },

    async fetchUser(item) {
      let results = await API.Admin.Users.getBy(item);

      this.selectedUser = results.data.Item;

      this.editForm.firstName = this.selectedUser.FirstName;
      this.editForm.lastName = this.selectedUser.LastName;
      this.editForm.middleName = this.selectedUser.MiddleName;
      this.editForm.username = this.selectedUser.Username;

      this.selectedUser.SystemRoles.forEach((role) => {
        this.editForm.systemRoles.push(role.Id);
      });
    },

    handleEdit(item) {
      console.log({ item });
      this.drawer.edit = true;
      this.drawer.loading = true;
      try {
        this.fetchUser(item);
        this.drawer.loading = false;
      } catch (error) {
        this.$notify.error({
          title: "Error",
          message: `Problem while fetching users. ${error}`,
        });
        this.loading = false;
      }
    },

    handleDelete(id) {
      this.dialog.show = true;
      this.dialog.userId = id;
    },

    onDelete() {
      API.Admin.Users.delete(this.dialog.userId).then(() => {
        this.dialog.show = false;

        this.$notify({
          title: "Success",
          message: "User deleted!",
          type: "success",
        });
        this.fetchUsers();
      });
    },
  },
  watch: {
    search: function (val) {
      if (val.length > 0) {
        this.debouncedGetAnswer();
      }
    },
  },
});
</script>

<style scoped>
.layout {
  --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  padding: 24px;
  height: 100%;
  background-color: #fff;
  border-radius: 12px;
}
.p-8 {
  padding: 32px;
}
</style>
