<template>
  <TransitionRoot as="template" :show="open">
    <Dialog
      as="div"
      static
      class="fixed inset-0 z-10 overflow-hidden"
      @close="open = false"
      :open="open"
    >
      <div class="absolute inset-0 overflow-hidden">
        <DialogOverlay class="absolute inset-0" />

        <div class="fixed inset-y-0 right-0 flex max-w-full pl-16">
          <TransitionChild
            as="template"
            enter="transform transition ease-in-out duration-500 sm:duration-700"
            enter-from="translate-x-full"
            enter-to="translate-x-0"
            leave="transform transition ease-in-out duration-500 sm:duration-700"
            leave-from="translate-x-0"
            leave-to="translate-x-full"
          >
            <div class="w-screen max-w-md">
              <el-form
                class="flex h-full flex-col divide-y divide-gray-200 bg-white shadow-xl"
              >
                <div class="h-0 flex-1 overflow-y-auto">
                  <div class="bg-indigo-700 py-6 px-4 sm:px-6">
                    <div class="flex items-center justify-between">
                      <DialogTitle class="text-lg font-medium text-white">
                        New User
                      </DialogTitle>
                      <div class="ml-3 flex h-7 items-center">
                        <button
                          type="button"
                          class="focus:outline-none rounded-md bg-indigo-700 text-indigo-200 hover:text-white focus:ring-2 focus:ring-white"
                          @click="open = false"
                        >
                          <span class="sr-only">Close panel</span>
                          <XIcon class="h-6 w-6" aria-hidden="true" />
                        </button>
                      </div>
                    </div>
                    <div class="mt-1">
                      <p class="text-sm text-indigo-300">
                        Get started by filling in the information below to
                        create new user.
                      </p>
                    </div>
                  </div>
                  <div class="flex flex-1 flex-col justify-between">
                    <div class="divide-y divide-gray-200 px-4 sm:px-6">
                      <div class="space-y-6 pt-6 pb-5">
                        <div>
                          <label
                            for="first_name"
                            class="block text-sm font-medium text-gray-900"
                          >
                            First Name
                          </label>
                          <div class="mt-1">
                            <el-input
                              v-model="createForm.firstName"
                              placeholder="Please input"
                            ></el-input>
                          </div>
                        </div>
                        <div>
                          <label
                            for="last_name"
                            class="block text-sm font-medium text-gray-900"
                          >
                            Last Name
                          </label>
                          <div class="mt-1">
                            <el-input
                              v-model="createForm.lastName"
                              placeholder="Please input"
                            ></el-input>
                          </div>
                        </div>
                        <div>
                          <label
                            for="middle_name"
                            class="block text-sm font-medium text-gray-900"
                          >
                            Middle Name
                          </label>
                          <div class="mt-1">
                            <el-input
                              v-model="createForm.middleName"
                              placeholder="Please input"
                            ></el-input>
                          </div>
                        </div>
                        <div>
                          <label
                            for="username"
                            class="block text-sm font-medium text-gray-900"
                          >
                            Username
                          </label>
                          <div class="mt-1">
                            <el-input
                              v-model="createForm.username"
                              placeholder="Must be unique"
                              type="email"
                            ></el-input>
                          </div>
                        </div>
                        <div>
                          <label
                            for="username"
                            class="block text-sm font-medium text-gray-900"
                          >
                            Password
                          </label>
                          <div class="mt-1">
                            <el-input
                              v-model="createForm.password"
                              class="focus:border-indigo-500 focus:ring-indigo-500"
                              placeholder="Please input password"
                              show-password
                            ></el-input>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="flex flex-shrink-0 justify-end px-4 py-4">
                  <el-button
                    @click="onCancel"
                    class="focus:outline-none rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:ring-indigo-500"
                    >Cancel</el-button
                  >
                  <el-button
                    type="primary"
                    @click="onSubmit"
                    class="focus:outline-none ml-4 inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:ring-indigo-500"
                    >Save</el-button
                  >
                </div>
              </el-form>
            </div>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script>
import { ref, reactive, watch } from "vue";
import {
  Dialog,
  DialogOverlay,
  DialogTitle,
  TransitionChild,
  TransitionRoot,
} from "@headlessui/vue";
import { XIcon } from "@heroicons/vue/outline";

export default {
  components: {
    Dialog,
    DialogOverlay,
    DialogTitle,
    TransitionChild,
    TransitionRoot,
    XIcon,
  },
  props: {
    openPanel: Boolean,
  },
  setup(props) {
    // const { openPanel } = toRefs(props);
    console.log("props.openPanel", props.openPanel);
    let openCreatePanel = ref(props.openPanel);
    console.log("openCreatePanel", openCreatePanel.value);
    const open = ref(false);

    watch(
      () => props.openPanel.value,
      (val) => {
        console.log("val", val);
        if (val) {
          open.value = val;
          openCreatePanel.value = false;
        }
      }
    );

    const createForm = reactive({
      firstName: "",
      lastName: "",
      middleName: "",
      username: "",
      password: "",
    });

    const rules = reactive({
      firstName: [
        {
          required: true,
          message: "Please input First Name",
          trigger: "blur",
        },
      ],
      lastName: [
        {
          required: true,
          message: "Please input Last Name",
          trigger: "blur",
        },
      ],
      username: [
        {
          required: true,
          message: "Please input Username",
          trigger: "blur",
        },
      ],
      password: [
        {
          required: true,
          message: "Please input Password",
          trigger: "blur",
        },
      ],
    });

    const onSubmit = () => {
      console.log({ createForm });
    };

    const onCancel = () => {
      open.value = false;
    };

    return {
      open,
      rules,
      onSubmit,
      onCancel,
      createForm,
    };
  },
};
</script>
